$main-color: #001011;
$second-color: #093a3e;
$third-color: #3aafb9;
$fourth-color: #64e9ee;
$fifth-color: #97c8eb;


* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rowdies', cursive;
  font-weight: 100;
  color: $fourth-color
}

h1{
  font-size: 7em;
}

h2{
  font-size: 5em;
  color: $second-color;
}

h3 {
  margin: 0.5em;
  font-size: 2.3em;
  font-weight: 800;
}

h5 {
  font-size: 2em;
  margin-bottom: 0.25em;
}

nav {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  background-color: $third-color;
  opacity: 0.6;
  div{
    margin: 50px;
    a{
      color :$second-color;
      margin: 0 5px;
      text-decoration: none;
      &:hover{
        color: $fourth-color;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 20vh;
}

.header-text{
  text-align: center;
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.flex-sa{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-center-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
  text-align: center;
}

.full-height {
  height: 100%;
  text-align: center;
}

.projects-wrapper {
  width: 100%;
}

.project-container {
  width: 45%;
  margin: 10px;
}

.interst-title {
  display: flex;
  justify-content: center;
  img {
    max-width: 15vh;
    max-height: 15vh;
    margin-left: 2em;
  }
}

.skills-wrapper {
  height: auto;
}

.bio-wrapper {
  padding: 2em;
  height: auto;
  width: 50%;
  line-height: 1.8em;
  text-align: center;
  p {
    font-size: 2em;
  }
}

.container {
  height: 100%;
  background-color: $main-color;
}

.header {
  height: 93vh;
  background-color: $main-color ;
  display: flex;
  padding: 20px;
}

.gold-title {
  font-size: 96px;
  color: $fifth-color;
}

.black-background {
  background-color: $main-color;
}

.reverse-title {
  width: 100%;
  text-align: center;
  background-color: #DFB984;
  font-size: 96px;
  color: #7F9191;
}

.half-width {
  width: 50%;
}

.half-width {
  width: 50vw;
}

.reading-image {
  height: 40vh;
  border: 10px solid $third-color;
}

.slideShow {
  margin: 3em;
  height: auto;
}

.slider-image{
  max-height: 70vh;
  max-width: 100%;
}

.right-arrow{
  font-size: 3em;
  color: #000;
  z-index: 99;
  cursor: pointer;
  user-select: none;
}

.left-arrow{
  font-size: 3em;
  color: #000;
  z-index: 99;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide-active {
  // height: 100%;
  opacity: 1;
  transition-duration: 1s;
  transform: scale(0.95);
  border: 1em solid $fourth-color;
  max-width: 80vw;

}

.slide-changer {
  display: flex;
  justify-content: space-around;
  width: 60%;
}
.hexagon {
  position: relative;
  width: 150px; 
  height: 86.60px;
  background-color: $second-color;
  margin: 60px 10px;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 75px solid transparent;
  border-right: 75px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 43.30px solid $second-color;
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 43.30px solid $second-color;
}

.red-border {
  border: 1px solid red;
}

.circle {
  height: 20em;
  width: 20em;
  border-radius: 50%;
  background-image: url('https://i.imgur.com/BHeBQmY.jpg');
  background-size: cover;
  background-position: center;
}

.bio-button {
  height: auto;
  font-size: 1em;
  padding: 1em;
  text-align: center;
  margin: 0 0.5em;
  border-radius: 10%;
  color: $main-color;
  background-color: $fourth-color;
  border: none;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: white;
  }
}

.project-info {
  margin-bottom: 10px;
  width: 50%;
}

.readme {
  margin: 0.5em;
}

input[type="radio"] {
  appearance: none;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $fourth-color;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.header-icon {
  font-size: 4em;
  margin: 0 1em;
  &:hover {
    transform: rotate(40deg);
    transition: 1s;
  }
}

.interests-wrapper {
  background-color: $second-color;
  padding-bottom: 2em;
}

.main-interest-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.interest-images {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  img {
    max-width: 10vh;
    max-height: 10vh;
  }
}

.mobile-nav-list {
  display: none;
}

.footer-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    margin: 0.2em 0;
  }
}

.mobile-nav {
  display: none;
}

@media screen and (max-width: 1470px) {
  .title, .subtitles{
    text-align: center;
  }
}

@media screen and (max-width: 900px) {
  .mike-image{
    display: none;
  }
  .header-left{
    width: 100%;
  }

}

@media screen and (max-width: 820px) {
  .header-left {
    h1 {
      font-size: 4em;
    }
  }
  .about-me-title {
    text-align: center;
  }
  .about-me-button-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    button {
      border: 2px solid $second-color;
      margin: 1px;
    }
  }
  .bio-wrapper {
    width: 90%;
    p {
      font-size: 1rem;
    }
  }
  .slide-active {
    border: 5px solid $fourth-color;
  }
  .project-info {
    width: 80%;
  }
  .project-text {
    text-align: center;
  }
  .project-container {
    width: 80%;
  }
  footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 1em;
  }
  a{
    margin: 0 0.25em;
  }
  .footer-info {
    p {
      display: none;
    }
  }
  .book-section {
    width: 80%
  }
  .last-book {
    display: none;
  }
  .reading-image {
    max-width: 100%;
    height: auto;
  }
  .full-nav {
    display: none;
  }
  .mobile-nav {
    display: block;
    margin-right: 2rem;
    cursor: pointer;
  }
  .mobile-nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5%;
    background-color: grey;
    width: 10rem;
    position: fixed;
    top: 5vh;
    right: 0;
    transition: ease-in-out 1s;
    opacity: 0.7;
    a{
      color: $main-color;
    }
  }


}